<template>
    <client-page>
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">발송정보를 입력해 주세요.</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">입고일 : 2022-08-18</p>
        </page-section>

        <page-section>
            <h2 class="tit">보내시는 분</h2>
            <v-divider class="my-20" />
            <div>
                <v-row>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="이름" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="연락처 (-없이 입력해 주세요.)" />
                    </v-col>
                </v-row>
            </div>
            <div class="pt-20">
                <text-list>
                    <text-list-item listName="주소" listText="경기도 안성시 대덕면 모산로 394 신진유통 물류센터" />
                </text-list>
            </div>
        </page-section>

        <page-section>
            <div>
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <h3 class="tit">받으시는 분</h3>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn small icon color="#bbb" class="h-auto mr-n4"><v-icon size="24">mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-20" />
                <v-row>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="이름" />
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--small">
                            <v-col>
                                <v-text-field outlined hide-details placeholder="우편번호" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-large color="grey" class="w-100px">검색</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="기본주소" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="상세주소" />
                    </v-col>
                </v-row>
            </div>

            <div class="mt-32">
                <v-row justify="space-between">
                    <v-col cols="auto">
                        <h3 class="tit">받으시는 분</h3>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn small icon color="#bbb" class="h-auto mr-n4"><v-icon size="24">mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
                <v-divider class="my-20" />
                <v-row>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="이름" />
                    </v-col>
                    <v-col cols="12">
                        <v-row class="row--small">
                            <v-col>
                                <v-text-field outlined hide-details placeholder="우편번호" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn x-large color="grey" class="w-100px">검색</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="기본주소" />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field outlined hide-details placeholder="상세주소" />
                    </v-col>
                </v-row>
            </div>
        </page-section>

        <page-section class="page-section--sm">
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large">배송지 추가하기</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large">입력 완료</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import TextList from "@/components/client/list/text-list.vue";
import TextListItem from "@/components/client/list/text-list-item.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TextList,
        TextListItem,
    },
    data: () => ({}),
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle(" ");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>

<style lang="scss" scoped></style>
