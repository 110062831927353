<template>
    <client-page>
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">상품을 선택해 주세요.</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">입고일 : 2022-08-18</p>
        </page-section>

        <page-section class="page-section--sm">
            <v-row align="center" justify="space-between">
                <v-col cols="auto">
                    <v-checkbox hide-details color="primary" label="전체 선택" />
                </v-col>
                <v-col cols="auto">
                    <!-- 상품 선택 dialog -->
                    <v-dialog v-model="dialog" scrollable max-width="500px" content-class="v-dialog--default">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" outlined block color="primary">일괄 상품 선택</v-btn>
                        </template>

                        <v-card flat tile>
                            <v-card-title>
                                <v-btn icon class="v-dialog--default__close" @click="dialog = false"><v-icon size="20">mdi-close</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <div>
                                    <v-row no-gutters>
                                        <v-col>
                                            <v-text-field outlined clearable hide-details placeholder="상품명을 입력해 주세요." />
                                        </v-col>
                                        <v-col cols="auto">
                                            <v-btn x-large icon class="mr-n12"><v-icon size="26">search</v-icon></v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-20">
                                    <v-row class="row--small">
                                        <v-col cols="6">
                                            <v-select outlined hide-details placeholder="대분류" />
                                        </v-col>
                                        <v-col cols="6">
                                            <v-select outlined hide-details placeholder="중분류" />
                                        </v-col>
                                    </v-row>
                                </div>
                                <div class="pt-40">
                                    <h3 class="tit mb-20">“굴비”</h3>
                                    <border-list no-gutters class="border-list--grey">
                                        <border-list-item>
                                            <v-row align="center">
                                                <v-col>
                                                    <p class="page-text font-weight-bold grey--text text--darken-4">굴비-황토염굴비(오가1.2)</p>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-row no-gutters class="input-number">
                                                        <v-col cols="auto">
                                                            <v-btn x-small icon outlined color="grey lighten-3"><v-icon size="18" color="grey">mdi-minus</v-icon></v-btn>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field dense outlined hide-details type="number" class="input-number__num" />
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <v-btn x-small icon outlined color="grey lighten-3"><v-icon size="18" color="grey">mdi-plus</v-icon></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </border-list-item>
                                        <border-list-item>
                                            <v-row align="center">
                                                <v-col>
                                                    <p class="page-text font-weight-bold grey--text text--darken-4">굴비-황토염굴비(오가1.2)</p>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <v-row no-gutters class="input-number">
                                                        <v-col cols="auto">
                                                            <v-btn x-small icon outlined color="grey lighten-3"><v-icon size="18" color="grey">mdi-minus</v-icon></v-btn>
                                                        </v-col>
                                                        <v-col>
                                                            <v-text-field dense outlined hide-details type="number" class="input-number__num" />
                                                        </v-col>
                                                        <v-col cols="auto">
                                                            <v-btn x-small icon outlined color="grey lighten-3"><v-icon size="18" color="grey">mdi-plus</v-icon></v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </border-list-item>
                                    </border-list>
                                </div>
                                <div class="pt-20">
                                    <v-btn outlined block color="primary" class="v-size--xx-large">상품담기</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <!-- // 상품 선택 dialog -->
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <v-row align="center" justify="space-between">
                <v-col cols="auto">
                    <v-checkbox hide-details color="primary">
                        <template v-slot:label>
                            <h3 class="tit grey--text text--darken-4">받으시는 분</h3>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col cols="auto">
                    <v-btn outlined color="primary">상품선택</v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-20" />
            <text-list>
                <text-list-item listName="이름" listText="김길동" />
                <text-list-item listName="연락처" listText="111-1111-1111" />
                <text-list-item listName="주소" listText="서울시 구로구 디지털로29길 38 707호" />
            </text-list>

            <v-row class="mt-8">
                <v-col cols="auto"><div class="min-w-44px"></div></v-col>
                <v-col>
                    <v-row align="center" class="font-size-18 grey--text text--darken-4">
                        <v-col>영광 굴비</v-col>
                        <v-col cols="4"><div class="text-center">13</div></v-col>
                        <v-col cols="auto">
                            <v-btn icon small><v-icon size="20">mdi-close</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-row align="center" class="font-size-18 grey--text text--darken-4 mt-2">
                        <v-col>영광 굴비</v-col>
                        <v-col cols="4"><div class="text-center">26</div></v-col>
                        <v-col cols="auto">
                            <v-btn icon small><v-icon size="20">mdi-close</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section>

        <page-section>
            <v-row align="center" justify="space-between">
                <v-col cols="auto">
                    <v-checkbox hide-details color="primary">
                        <template v-slot:label>
                            <h3 class="tit grey--text text--darken-4">받으시는 분</h3>
                        </template>
                    </v-checkbox>
                </v-col>
                <v-col cols="auto">
                    <v-btn outlined color="primary">상품선택</v-btn>
                </v-col>
            </v-row>
            <v-divider class="my-20" />
            <text-list>
                <text-list-item listName="이름" listText="김길동" />
                <text-list-item listName="연락처" listText="111-1111-1111" />
                <text-list-item listName="주소" listText="서울시 구로구 디지털로29길 38 707호" />
            </text-list>
        </page-section>

        <page-section class="page-section--sm">
            <v-btn block color="primary" class="v-size--xx-large">선택 완료</v-btn>
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import BorderList from "@/components/client/list/border-list.vue";
import BorderListItem from "@/components/client/list/border-list-item.vue";
import TextList from "@/components/client/list/text-list.vue";
import TextListItem from "@/components/client/list/text-list-item.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        BorderList,
        BorderListItem,
        TextList,
        TextListItem,
    },
    data() {
        return {
            dialog: false,
        };
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("상품선택");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>

<style lang="scss" scoped></style>
