<template>
    <client-page>
        <page-section class="page-section--first">
            <p class="page-text page-text--lg font-weight-medium primary--text">입고일 : 2022-08-18</p>
            <h3 class="tit mt-20">보내시는 분</h3>
            <v-divider class="my-20" />
            <text-list>
                <text-list-item listName="이름" listText="홍길동" />
                <text-list-item listName="연락처" listText="111-1111-1111" />
                <text-list-item listName="주소" listText="서울시 구로구 디지털로29길 38 707호" />
            </text-list>
            <div class="pt-20">
                <v-btn x-large block outlined color="primary"><v-icon class="mr-10">mdi-camera-enhance</v-icon>영수증 첨부</v-btn>
            </div>
            <div class="pt-20">
                <v-row class="row--small">
                    <v-col cols="6">
                        <v-btn x-large block outlined color="primary">명함 첨부</v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn x-large block outlined color="primary">인사장 첨부</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="pt-20">
                <v-text-field outlined hide-details placeholder="메세지를 입력해주세요." />
            </div>
        </page-section>

        <page-section>
            <h3 class="tit">받으시는 분</h3>
            <v-divider class="my-20" />
            <text-list class="pb-20">
                <text-list-item listName="이름" listText="김철수" />
                <text-list-item listName="연락처" listText="111-1111-1111" />
                <text-list-item listName="주소" listText="서울시 구로구 디지털로29길 38 707호" />
            </text-list>
            <div>
                <table class="table-default">
                    <tr>
                        <th>상품명</th>
                        <td>굴비-황토염굴비(오가1.2)</td>
                    </tr>
                    <tr>
                        <th>수량</th>
                        <td>65개</td>
                    </tr>
                </table>
            </div>
            <div class="pt-20">
                <table class="table-default">
                    <tr>
                        <th>상품명</th>
                        <td>굴비-황토염굴비(오가1.2)</td>
                    </tr>
                    <tr>
                        <th>수량</th>
                        <td>65개</td>
                    </tr>
                </table>
            </div>
        </page-section>

        <page-section>
            <h3 class="tit">받으시는 분</h3>
            <v-divider class="my-20" />
            <text-list class="pb-20">
                <text-list-item listName="이름" listText="박영수" />
                <text-list-item listName="연락처" listText="111-1111-1111" />
                <text-list-item listName="주소" listText="서울시 구로구 디지털로29길 38 707호" />
            </text-list>
            <div>
                <table class="table-default">
                    <tr>
                        <th>상품명</th>
                        <td>굴비-황토염굴비(오가1.2)</td>
                    </tr>
                    <tr>
                        <th>수량</th>
                        <td>65개</td>
                    </tr>
                </table>
            </div>
            <div class="pt-20">
                <table class="table-default">
                    <tr>
                        <th>상품명</th>
                        <td>굴비-황토염굴비(오가1.2)</td>
                    </tr>
                    <tr>
                        <th>수량</th>
                        <td>65개</td>
                    </tr>
                </table>
            </div>
        </page-section>

        <page-section class="page-section--sm">
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large">내역 수정</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large">발주 완료</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import TextList from "@/components/client/list/text-list.vue";
import TextListItem from "@/components/client/list/text-list-item.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TextList,
        TextListItem,
    },
    data: () => ({}),
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("발주내역확인");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>

<style lang="scss" scoped></style>
