<template>
    <client-page>
        <page-section class="page-section--first">
            <table class="table-default">
                <tr>
                    <th>발주번호</th>
                    <td>2022110900001</td>
                </tr>
                <tr>
                    <th>입고일</th>
                    <td>2022-01-01</td>
                </tr>
                <tr>
                    <th>발주일</th>
                    <td>2022-01-01</td>
                </tr>
                <tr>
                    <th>접수상태</th>
                    <td>처리대기</td>
                </tr>
                <tr>
                    <th class="red--text">상품명</th>
                    <td>보리굴비</td>
                </tr>
                <tr>
                    <th class="red--text">담당자</th>
                    <td>홍길동</td>
                </tr>
                <tr>
                    <th class="red--text">내용</th>
                    <td>1개 교환조치 완료</td>
                </tr>
                <tr>
                    <th class="red--text">클레임</th>
                    <td>[굴비-황토염굴비(오가1.2)] 굴비가 상했어요. 확인 부탁드립니다.</td>
                </tr>
            </table>
        </page-section>

        <page-section>
            <div>
                <table class="table-default">
                    <tr>
                        <th>상품명</th>
                        <td>굴비-황토염굴비(오가1.2)</td>
                    </tr>
                    <tr>
                        <th>수량</th>
                        <td>65개</td>
                    </tr>
                </table>
            </div>
            <div class="pt-20">
                <table class="table-default">
                    <tr>
                        <th>상품명</th>
                        <td>굴비-황토염굴비(오가1.2)</td>
                    </tr>
                    <tr>
                        <th>수량</th>
                        <td>65개</td>
                    </tr>
                </table>
            </div>
        </page-section>

        <page-section class="page-section--sm">
            <v-row class="row--small">
                <!-- 발주 완료 -->
                <v-col cols="6">
                    <v-btn block disabled class="v-size--xx-large">발주 완료</v-btn>
                </v-col>
                <!-- // 발주 완료 -->
                <!-- 클레임 -->
                <v-col cols="6">
                    <!-- 클레임 dialog -->
                    <v-dialog-default title="클레임" contentClass="align-self-start">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" block outlined color="secondary" class="v-size--xx-large">클레임</v-btn>
                        </template>

                        <div>
                            <v-textarea outlined hide-details no-resize rows="6" placeholder="상품명과 내용을 입력하세요." />
                        </div>
                        <div class="pt-20">
                            <v-btn block outlined color="primary" class="v-size--xx-large">저장</v-btn>
                        </div>
                    </v-dialog-default>
                    <!-- // 클레임 dialog -->
                </v-col>
                <!-- // 클레임 -->
                <v-col cols="6">
                    <v-btn block outlined color="grey lighten-3" class="v-size--xx-large">발주 취소</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large">리스트</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import VDialogDefault from "@/components/plugins/vuetify/v-dialog-default.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        VDialogDefault,
    },
    data() {
        return {};
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("발주이력");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>

<style lang="scss" scoped></style>
