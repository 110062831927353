var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("상품을 선택해 주세요.")]), _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v("입고일 : 2022-08-18")])]), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "color": "primary",
      "label": "전체 선택"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "500px",
      "content-class": "v-dialog--default"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "outlined": "",
            "block": "",
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("일괄 상품 선택")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', [_c('v-btn', {
    staticClass: "v-dialog--default__close",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "상품명을 입력해 주세요."
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-n12",
    attrs: {
      "x-large": "",
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "26"
    }
  }, [_vm._v("search")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "대분류"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "중분류"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-40"
  }, [_c('h3', {
    staticClass: "tit mb-20"
  }, [_vm._v("“굴비”")]), _c('border-list', {
    staticClass: "border-list--grey",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('border-list-item', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('p', {
    staticClass: "page-text font-weight-bold grey--text text--darken-4"
  }, [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "input-number",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v("mdi-minus")])], 1)], 1), _c('v-col', [_c('v-text-field', {
    staticClass: "input-number__num",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "type": "number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1)], 1)], 1), _c('border-list-item', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('p', {
    staticClass: "page-text font-weight-bold grey--text text--darken-4"
  }, [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "input-number",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v("mdi-minus")])], 1)], 1), _c('v-col', [_c('v-text-field', {
    staticClass: "input-number__num",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "type": "number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "outlined": "",
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("상품담기")])], 1)])], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('h3', {
          staticClass: "tit grey--text text--darken-4"
        }, [_vm._v("받으시는 분")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("상품선택")])], 1)], 1), _c('v-divider', {
    staticClass: "my-20"
  }), _c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "이름",
      "listText": "김길동"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "연락처",
      "listText": "111-1111-1111"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": "서울시 구로구 디지털로29길 38 707호"
    }
  })], 1), _c('v-row', {
    staticClass: "mt-8"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "min-w-44px"
  })]), _c('v-col', [_c('v-row', {
    staticClass: "font-size-18 grey--text text--darken-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_vm._v("영광 굴비")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v("13")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1), _c('v-row', {
    staticClass: "font-size-18 grey--text text--darken-4 mt-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_vm._v("영광 굴비")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_vm._v("26")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1)], 1)], 1)], 1), _c('page-section', [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('h3', {
          staticClass: "tit grey--text text--darken-4"
        }, [_vm._v("받으시는 분")])];
      },
      proxy: true
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("상품선택")])], 1)], 1), _c('v-divider', {
    staticClass: "my-20"
  }), _c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "이름",
      "listText": "김길동"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "연락처",
      "listText": "111-1111-1111"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": "서울시 구로구 디지털로29길 38 707호"
    }
  })], 1)], 1), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("선택 완료")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }