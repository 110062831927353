var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("{입고일}을 선택해주세요.")])]), _c('page-section', [_c('div', [_c('v-text-field', {
    attrs: {
      "append-icon": "event",
      "outlined": "",
      "hide-details": "",
      "readonly": "",
      "placeholder": "0000-00-00"
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  }), _c('v-card', {
    staticClass: "py-10 mt-12",
    attrs: {
      "flat": "",
      "color": "grey lighten-5"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "weekday-format": _vm.getDay,
      "month-format": _vm.getMonth,
      "title-date-format": _vm.getMonth,
      "header-date-format": _vm.getHeaderTitleMonth,
      "no-title": "",
      "scrollable": "",
      "type": "date",
      "color": "secondary",
      "locale": "en-US"
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("선택 완료")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }