var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--sm"
  }, [_c('v-dialog-default', {
    attrs: {
      "contentClass": "align-self-start"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-row', _vm._g(_vm._b({
          attrs: {
            "no-gutters": ""
          }
        }, 'v-row', attrs, false), on), [_c('v-col', [_c('v-text-field', {
          attrs: {
            "outlined": "",
            "readonly": "",
            "hide-details": "",
            "placeholder": "발주번호 / 상품명을 입력해주세요."
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-btn', {
          staticClass: "mr-n12 v-btn--none-active-bg",
          attrs: {
            "x-large": "",
            "icon": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "size": "26"
          }
        }, [_vm._v("search")])], 1)], 1)], 1)];
      }
    }])
  }, [_c('div', [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": "발주번호 / 상품명을 입력하세요."
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "mr-n12",
    attrs: {
      "x-large": "",
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "26"
    }
  }, [_vm._v("search")])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('div', {
    staticClass: "font-size-12 font-weight-medium mb-4"
  }, [_vm._v("입고일")]), _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-date-field', {
    attrs: {
      "append-icon": "event",
      "outlined": "",
      "hide-details": "",
      "placeholder": "0000-00-00"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "page-text"
  }, [_vm._v("~")])]), _c('v-col', [_c('v-date-field', {
    attrs: {
      "append-icon": "event",
      "outlined": "",
      "hide-details": "",
      "placeholder": "0000-00-00"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("조회")])], 1)])], 1), _c('page-section', [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "접수유형"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "접수상태"
    }
  })], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('border-list', [_c('border-list-item', {
    attrs: {
      "to": "/shinjin/history/view"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', {
    staticStyle: {
      "width": "calc(100% - 88px)"
    }
  }, [_c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("택배 일괄")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("2022110900010")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("2022-08-18")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("홍남희")])]), _c('v-chip', {
    staticClass: "w-64px v-btn--none-active-bg",
    attrs: {
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("처리완료")])]), _c('v-icon', {
    staticClass: "mr-n8",
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-chevron-right")])], 1)]), _c('border-list-item', {
    attrs: {
      "to": "/shinjin/history/view"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', {
    staticStyle: {
      "width": "calc(100% - 88px)"
    }
  }, [_c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("택배 일괄")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("2022110900010")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("2022-08-18")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("홍길동")])]), _c('v-chip', {
    staticClass: "w-64px v-btn--none-active-bg",
    attrs: {
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "grey--text text--lighten-1"
  }, [_vm._v("처리대기")])]), _c('v-icon', {
    staticClass: "mr-n8",
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-chevron-right")])], 1)]), _c('border-list-item', {
    attrs: {
      "to": "/shinjin/history/view"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', {
    staticStyle: {
      "width": "calc(100% - 88px)"
    }
  }, [_c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("택배 일괄")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("2022110900010")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("2022-08-18")]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v("홍길동")])]), _c('v-chip', {
    staticClass: "w-64px v-btn--none-active-bg",
    attrs: {
      "outlined": ""
    }
  }, [_c('span', {
    staticClass: "secondary--text"
  }, [_vm._v("클레임")])]), _c('v-icon', {
    staticClass: "mr-n8",
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-chevron-right")])], 1)])], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("더 보기")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }