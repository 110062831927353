var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("발주번호")]), _c('td', [_vm._v("2022110900001")])]), _c('tr', [_c('th', [_vm._v("입고일")]), _c('td', [_vm._v("2022-01-01")])]), _c('tr', [_c('th', [_vm._v("발주일")]), _c('td', [_vm._v("2022-01-01")])]), _c('tr', [_c('th', [_vm._v("접수상태")]), _c('td', [_vm._v("처리대기")])]), _c('tr', [_c('th', {
    staticClass: "red--text"
  }, [_vm._v("상품명")]), _c('td', [_vm._v("보리굴비")])]), _c('tr', [_c('th', {
    staticClass: "red--text"
  }, [_vm._v("담당자")]), _c('td', [_vm._v("홍길동")])]), _c('tr', [_c('th', {
    staticClass: "red--text"
  }, [_vm._v("내용")]), _c('td', [_vm._v("1개 교환조치 완료")])]), _c('tr', [_c('th', {
    staticClass: "red--text"
  }, [_vm._v("클레임")]), _c('td', [_vm._v("[굴비-황토염굴비(오가1.2)] 굴비가 상했어요. 확인 부탁드립니다.")])])])]), _c('page-section', [_c('div', [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v("65개")])])])]), _c('div', {
    staticClass: "pt-20"
  }, [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v("65개")])])])])]), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "disabled": ""
    }
  }, [_vm._v("발주 완료")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-dialog-default', {
    attrs: {
      "title": "클레임",
      "contentClass": "align-self-start"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": "",
            "outlined": "",
            "color": "secondary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("클레임")])];
      }
    }])
  }, [_c('div', [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "no-resize": "",
      "rows": "6",
      "placeholder": "상품명과 내용을 입력하세요."
    }
  })], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("저장")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "grey lighten-3"
    }
  }, [_vm._v("발주 취소")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }