<template>
    <client-page>
        <page-section class="page-section--first page-section--sm">
            <!-- 검색 dialog -->
            <v-dialog-default contentClass="align-self-start">
                <template #activator="{ attrs, on }">
                    <v-row v-bind="attrs" v-on="on" no-gutters>
                        <v-col>
                            <v-text-field outlined readonly hide-details placeholder="발주번호 / 상품명을 입력해주세요." />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-large icon class="mr-n12 v-btn--none-active-bg"><v-icon size="26">search</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </template>

                <div>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field outlined clearable hide-details placeholder="발주번호 / 상품명을 입력하세요." />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn x-large icon class="mr-n12"><v-icon size="26">search</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </div>
                <div class="pt-20">
                    <div class="font-size-12 font-weight-medium mb-4">입고일</div>
                    <v-row align="center" class="row--small">
                        <v-col>
                            <v-date-field append-icon="event" outlined hide-details placeholder="0000-00-00" />
                        </v-col>
                        <v-col cols="auto"><span class="page-text">~</span></v-col>
                        <v-col>
                            <v-date-field append-icon="event" outlined hide-details placeholder="0000-00-00" />
                        </v-col>
                    </v-row>
                </div>
                <div class="pt-20">
                    <v-btn block color="primary" class="v-size--xx-large">조회</v-btn>
                </div>
            </v-dialog-default>
            <!-- // 검색 dialog -->
        </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-select outlined hide-details placeholder="접수유형" />
                </v-col>
                <v-col cols="6">
                    <v-select outlined hide-details placeholder="접수상태" />
                </v-col>
            </v-row>

            <div class="pt-20">
                <border-list>
                    <border-list-item to="/shinjin/history/view">
                        <div class="d-flex align-center justify-space-between">
                            <div style="width: calc(100% - 88px)">
                                <p class="page-text text-truncate">택배 일괄</p>
                                <p class="page-text text-truncate">2022110900010</p>
                                <p class="page-text text-truncate">2022-08-18</p>
                                <p class="page-text text-truncate">홍남희</p>
                            </div>
                            <v-chip outlined class="w-64px v-btn--none-active-bg"><span class="primary--text">처리완료</span></v-chip>
                            <v-icon size="24" class="mr-n8">mdi-chevron-right</v-icon>
                        </div>
                    </border-list-item>
                    <border-list-item to="/shinjin/history/view">
                        <div class="d-flex align-center justify-space-between">
                            <div style="width: calc(100% - 88px)">
                                <p class="page-text text-truncate">택배 일괄</p>
                                <p class="page-text text-truncate">2022110900010</p>
                                <p class="page-text text-truncate">2022-08-18</p>
                                <p class="page-text text-truncate">홍길동</p>
                            </div>
                            <v-chip outlined class="w-64px v-btn--none-active-bg"><span class="grey--text text--lighten-1">처리대기</span></v-chip>
                            <v-icon size="24" class="mr-n8">mdi-chevron-right</v-icon>
                        </div>
                    </border-list-item>
                    <border-list-item to="/shinjin/history/view">
                        <div class="d-flex align-center justify-space-between">
                            <div style="width: calc(100% - 88px)">
                                <p class="page-text text-truncate">택배 일괄</p>
                                <p class="page-text text-truncate">2022110900010</p>
                                <p class="page-text text-truncate">2022-08-18</p>
                                <p class="page-text text-truncate">홍길동</p>
                            </div>
                            <v-chip outlined class="w-64px v-btn--none-active-bg"><span class="secondary--text">클레임</span></v-chip>
                            <v-icon size="24" class="mr-n8">mdi-chevron-right</v-icon>
                        </div>
                    </border-list-item>
                </border-list>
            </div>

            <div class="pt-20">
                <v-btn block outlined color="primary">더 보기</v-btn>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import BorderList from "@/components/client/list/border-list.vue";
import BorderListItem from "@/components/client/list/border-list-item.vue";
import VDialogDefault from "@/components/plugins/vuetify/v-dialog-default.vue";
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        BorderList,
        BorderListItem,
        VDialogDefault,
        VDateField,
    },
    data() {
        return {};
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("발주이력");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
    },
};
</script>

<style lang="scss" scoped></style>
