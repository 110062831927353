var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("발송정보를 입력해 주세요.")]), _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v("입고일 : 2022-08-18")])]), _c('page-section', [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("보내시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이름"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "연락처 (-없이 입력해 주세요.)"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": "경기도 안성시 대덕면 모산로 394 신진유통 물류센터"
    }
  })], 1)], 1)], 1), _c('page-section', [_c('div', [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("받으시는 분")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto mr-n4",
    attrs: {
      "small": "",
      "icon": "",
      "color": "#bbb"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이름"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "우편번호"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "x-large": "",
      "color": "grey"
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "기본주소"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "상세주소"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "mt-32"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("받으시는 분")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "h-auto mr-n4",
    attrs: {
      "small": "",
      "icon": "",
      "color": "#bbb"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-close")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "my-20"
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이름"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "우편번호"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "x-large": "",
      "color": "grey"
    }
  }, [_vm._v("검색")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "기본주소"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "상세주소"
    }
  })], 1)], 1)], 1)]), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("배송지 추가하기")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("입력 완료")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }