var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text"
  }, [_vm._v("입고일 : 2022-08-18")]), _c('h3', {
    staticClass: "tit mt-20"
  }, [_vm._v("보내시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "이름",
      "listText": "홍길동"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "연락처",
      "listText": "111-1111-1111"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": "서울시 구로구 디지털로29길 38 707호"
    }
  })], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    staticClass: "mr-10"
  }, [_vm._v("mdi-camera-enhance")]), _vm._v("영수증 첨부")], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("명함 첨부")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-large": "",
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("인사장 첨부")])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "메세지를 입력해주세요."
    }
  })], 1)], 1), _c('page-section', [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("받으시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('text-list', {
    staticClass: "pb-20"
  }, [_c('text-list-item', {
    attrs: {
      "listName": "이름",
      "listText": "김철수"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "연락처",
      "listText": "111-1111-1111"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": "서울시 구로구 디지털로29길 38 707호"
    }
  })], 1), _c('div', [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v("65개")])])])]), _c('div', {
    staticClass: "pt-20"
  }, [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v("65개")])])])])], 1), _c('page-section', [_c('h3', {
    staticClass: "tit"
  }, [_vm._v("받으시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('text-list', {
    staticClass: "pb-20"
  }, [_c('text-list-item', {
    attrs: {
      "listName": "이름",
      "listText": "박영수"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "연락처",
      "listText": "111-1111-1111"
    }
  }), _c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": "서울시 구로구 디지털로29길 38 707호"
    }
  })], 1), _c('div', [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v("65개")])])])]), _c('div', {
    staticClass: "pt-20"
  }, [_c('table', {
    staticClass: "table-default"
  }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v("굴비-황토염굴비(오가1.2)")])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v("65개")])])])])], 1), _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    }
  }, [_vm._v("내역 수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    }
  }, [_vm._v("발주 완료")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }