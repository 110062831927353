<template>
    <client-page>
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">{입고일}을 선택해주세요.</h2>
        </page-section>

        <page-section>
            <div>
                <v-text-field v-model="date" append-icon="event" outlined hide-details readonly placeholder="0000-00-00" />
                <v-card flat color="grey lighten-5" class="py-10 mt-12">
                    <v-date-picker
                        v-model="date"
                        :weekday-format="getDay"
                        :month-format="getMonth"
                        :title-date-format="getMonth"
                        :header-date-format="getHeaderTitleMonth"
                        no-title
                        scrollable
                        type="date"
                        color="secondary"
                        locale="en-US" />
                </v-card>
            </div>
            <div class="pt-20">
                <v-btn block color="primary" class="v-size--xx-large">선택 완료</v-btn>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        ClientPage,
        PageSection,
    },
    data: () => ({
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    }),
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle(" ");
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle();
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),

        getDay(date) {
            const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
            let i = new Date(date).getDay(date);
            return daysOfWeek[i];
        },
        getMonth(date) {
            const monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
            let i = new Date(date).getMonth(date);
            return monthName[i];
        },
        getHeaderTitleMonth(date) {
            const monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
            let i = new Date(date).getMonth(date);
            const year = new Date(date).getFullYear(date);
            const month = monthName[i];
            return `${year}년 ${month}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-picker,
::v-deep .theme--light.v-picker__body {
    background-color: transparent;
}
.v-picker {
    width: 100%;
}
::v-deep .v-date-picker-table--date .v-btn {
    height: 40px;
    width: 40px;
    border-radius: 100% !important;
}
</style>
